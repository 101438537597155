import { useEffect, useState } from 'react';
import DealsTable from 'features/DealsTable/ui/DealsTable';
import { ModalKeys } from 'shared/components/ui/RenderModal/model/enums';
import { useModalState } from 'shared/components/ui/RenderModal/model/useModalState';
import { AdaptiveFullScreenLoader } from 'shared/components/full-screen-loader';
import { getDeals } from 'shared/api/getDeals';
import { LoaderWrapper, TableWrapper } from '../PaymentMethodPage.styles';
import { useTransactionsState } from '../../model/useTransactionsState';

export const DealsTab = ({ requisiteName }: { requisiteName: string }) => {
  const { dealsMeta, setDealsMeta, deals, points, setDeals } =
    useTransactionsState();
  const { openModal } = useModalState();
  const [forceDeals, setForceDeals] = useState(false);
  const [isDealsEmpty, setIsDealsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleForce = () => {
    setForceDeals((prev) => !prev);
  };
  const handleOpenModalCancel = (id: number, isBuyingCrypto: boolean) => {
    openModal(ModalKeys.ModalCancelOffer, {
      id: id,
      isBuyingCrypto: isBuyingCrypto,
      callback: handleForce,
    });
  };

  const fetchDeals = async () => {
    const dealsData = await getDeals(
      [
        {
          field: 'requisite.currency.name',
          operator: '=',
          value: requisiteName,
        },
      ],
      dealsMeta.current_page
    );
    setDeals(dealsData);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await fetchDeals();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dealsMeta.current_page, forceDeals]);

  return (
    <TableWrapper>
      {isLoading ? (
        <LoaderWrapper>
          <AdaptiveFullScreenLoader />
        </LoaderWrapper>
      ) : (
        <DealsTable
          refetchDeals={fetchDeals}
          pagination={{
            current: dealsMeta.current_page,
            total: dealsMeta.total,
            pageSize: dealsMeta.per_page,
          }}
          setPagination={setDealsMeta}
          deals={deals}
          points={points}
          callbacks={{
            onCancel: handleOpenModalCancel,
          }}
          isDealsEmpty={isDealsEmpty}
        />
      )}
    </TableWrapper>
  );
};
