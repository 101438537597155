import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { IExchangePoint } from 'interfaces';
import { IMeta } from 'shared/interfaces';
import { TDeals, TOffers } from 'shared/types';

interface ITransactionsState {
  setOffers: (offers: { data: TOffers[]; meta: IMeta }) => void;
  setDeals: (deals: { data: TDeals[]; meta: IMeta }) => void;
  offers: TOffers[];
  deals: TDeals[];
  setOffersMeta: (value: number) => void;
  setDealsMeta: (value: number) => void;
  dealsMeta: IMeta;
  offersMeta: IMeta;
  setPoints: (points: IExchangePoint[]) => void;
  points: IExchangePoint[];
}

export const useTransactionsState = create<ITransactionsState>()(
  devtools((set) => ({
    offers: [],
    deals: [],
    dealsMeta: {} as IMeta,
    offersMeta: {} as IMeta,
    points: [],
    setOffers: (offers) =>
      set(
        produce((draft) => {
          draft.offers = offers.data;
          draft.offersMeta = {
            ...draft.offersMeta,
            total: offers.meta.total,
            last_page: offers.meta.last_page,
          };
        }),
        false,
        { type: 'useTransactionsState => setOffers' }
      ),
    setDeals: (deals) =>
      set(
        produce((draft) => {
          draft.deals = deals.data;
          draft.dealsMeta = {
            ...draft.dealsMeta,
            total: deals.meta.total,
            last_page: deals.meta.last_page,
          };
        }),
        false,
        { type: 'useTransactionsState => setDeals' }
      ),
    setDealsMeta: (pagination) =>
      set(
        produce((draft) => {
          draft.dealsMeta = { ...draft.dealsMeta, current_page: pagination };
        }),
        false,
        { type: 'useTransactionsState => setDealsMeta' }
      ),
    setOffersMeta: (pagination) =>
      set(
        produce((draft) => {
          draft.offersMeta = { ...draft.offersMeta, current_page: pagination };
        }),
        false,
        { type: 'useTransactionsState => setOffersMeta' }
      ),
    setPoints: (points) =>
      set(
        produce((draft) => {
          draft.points = points;
        }),
        false,
        { type: 'useTransactionsState => setPoints' }
      ),
  }))
);
