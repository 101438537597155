import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface ICurrencyIconProps {
  code: string
  fallbackIcon?: ReactNode
  className?: string
  inline?: boolean
}

const EXCHANGE_MAP_URL = process.env.REACT_APP_EXCHANGE_MAP_URL

/**
 * Retrieves the currency icon for the given code.
 *
 * @param {string} code - The code of the currency.
 * @param {ReactNode} fallbackIcon - Fallback icon element. If string - used as url
 * @param {string} className - Extends icon styles. Ignores fallback icon provided as ReactElement
 * @param {boolean} inline - when set, icon is rendered with inline-ready styles
 * @return {JSX.Element} 
 */
export const CurrencyIcon = ({
  code,
  className,
  inline,
  fallbackIcon
}: ICurrencyIconProps): JSX.Element => {
  const [pending, setPending] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const imageUrl = `${EXCHANGE_MAP_URL}/assets/currencies/${code}.svg`

  useEffect(() => {
    const image = new Image()
    setLoaded(false)
    setPending(true)

    image.onerror = () => {
      setPending(false)
    }
    image.onload = () => {
      setLoaded(true)
      setPending(false)
    }

    image.src = imageUrl
  }, [imageUrl])

  const isLoaded = !pending && loaded

  // not loaded - return fallback
  if (!isLoaded) {
    const isString = typeof fallbackIcon === 'string'

    return isString ? (
      <img className={className} src={fallbackIcon} alt={code} title={code} />
    ) : <>{fallbackIcon}</>
  }

  return inline 
    ? <ImageInlineStyled className={className} src={imageUrl} alt={code} title={code} />
    : <img className={className} src={imageUrl} alt={code} title={code} />;
};

const ImageInlineStyled = styled.img`
  background: transparent;
  display: inline-block;
  height: 1em;
  vertical-align: -0.125em;
  color: inherit;
`;
