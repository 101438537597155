import { Icons } from '@pankod/refine-antd';
import {
  IconInfoOutlineStyled,
  LabelCaption,
  LabelText,
  PopoverBlue,
} from './CreateOffer.styles';
import { TooltipPlacement } from 'antd/es/tooltip';

interface ITooltipLabel {
  tooltipText?: string;
  labelText: string;
  caption?: string;
  infoIconContent?: string | null;
  tooltipOnLabel?: boolean;
  placement?: TooltipPlacement;
  requiredField?: boolean;
  isHideTooltip?: boolean;
}

const LabelWithPopover = ({
  tooltipText,
  labelText,
  caption,
  infoIconContent,
  placement = 'topRight',
  requiredField = false,
  tooltipOnLabel = false,
  isHideTooltip = false,
}: ITooltipLabel) => {
  if (isHideTooltip) {
    return <LabelText required={requiredField}>{labelText}</LabelText>;
  }
  if (tooltipOnLabel) {
    return (
      <PopoverBlue
        overlayInnerStyle={{ maxWidth: '320px' }}
        placement={placement}
        content={tooltipText}
      >
        <LabelText required={requiredField}>{labelText}</LabelText>
      </PopoverBlue>
    );
  }
  return (
    <>
      <LabelText required={requiredField}>
        {labelText}
        {infoIconContent && (
          <PopoverBlue
            overlayInnerStyle={{ maxWidth: '320px' }}
            placement={placement}
            content={infoIconContent}
          >
            <IconInfoOutlineStyled />
          </PopoverBlue>
        )}
      </LabelText>

      <PopoverBlue
        overlayInnerStyle={{ maxWidth: '320px' }}
        placement={placement}
        content={tooltipText}
      >
        <LabelCaption>{caption}</LabelCaption>
      </PopoverBlue>
    </>
  );
};

export default LabelWithPopover;
