export function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, (match) => match[1].toUpperCase());
}

export function toCamelCaseArray<T, K>(arr: T[]): K[] {
  return arr.map((obj) => {
    return Object.fromEntries(
      Object.entries(obj as Record<string, unknown>).map(([key, value]) => [
        snakeToCamel(key),
        value,
      ])
    );
  }) as K[];
}
