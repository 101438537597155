import styled, { css } from 'styled-components';
import {
  Form,
  Popover,
  Segmented,
  Row,
  Button,
  Icons,
} from '@pankod/refine-antd';
import { SwapOutlined } from '@ant-design/icons';

export const CreateOfferContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h4 {
    margin: 0;
  }
`;
export const CreateOfferFormInner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
`;

export const FormItemsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const CreateOfferFormItem = styled(Form.Item)<{ $small?: boolean }>`
  & .ant-form-item-required {
    color: rgba(0, 0, 0, 0.65);
    position: relative;

    &::before {
      position: absolute;
      top: 2px;
      right: -12px;
      margin-inline-end: 0;
    }
  }

  & .ant-form-item-explain-error {
    font-size: 12px;
  }

  & label {
    display: flex;
    justify-content: space-between;
    width: 100%;

    label > span {
      cursor: pointer;
    }
  }

  ${({ $small }) =>
    $small &&
    css`
      width: 100%;
    `}
`;

export const IconInfoOutlineStyled = styled(Icons.InfoCircleOutlined)`
  color: #00000073;
  padding-left: 5px;
`;

export const PopoverBlue = styled(Popover).attrs({
  color: 'rgba(230, 244, 255, 1)',
})``;

export const LabelText = styled.span<{ required?: boolean }>`
  ${({ required }) =>
    required &&
    css`
      &::after {
        display: inline-block;
        margin-inline-start: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    `}
`;

export const SegmentedBlue = styled(Segmented)`
  font-size: 12px;
  background-color: #0000000f;

  .ant-segmented-item {
    padding: 4px 40px;
    background-color: transparent;
  }

  .ant-segmented-item.ant-segmented-item-selected,
  .ant-segmented-thumb {
    background-color: rgba(105, 177, 255, 1);
    color: rgba(252, 252, 253, 1);
  }
`;

export const CourseInputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const ReverseCourseCaption = styled.span`
  white-space: nowrap;
`;

export const LabelCaption = styled.span`
  color: rgba(0, 0, 0, 0.45);
`;

export const SwapOutlinedStyled = styled(SwapOutlined)`
  color: #00000040;
  font-size: 24px;
`;

export const SpinWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const SelectRow = styled(Row)`
  padding: '8px 0 2px';
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
`;

export const CommentWrapper = styled.div`
  grid-column: span 3;
`;

export const OptionItemContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100px;
`;

export const PaymentMethodTitle = styled.div`
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
`;

export const NotFoundStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`;

export const NotFoundButtonStyled = styled(Button)`
  margin-top: 16px;
`;

export const NotFoundTitleStyled = styled.div`
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 14px;
`;
