import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { IFreezingPeriod } from './types';
import BigNumber from 'bignumber.js';
import {
  DECIMAL_PART_EIGHT,
  DECIMAL_PART_TWO,
  MAX_MINUTES_TOTAL,
  MIN_MINUTES,
} from './const';
import { validateNumericInput } from '../../../shared/helpers/script';
import { TOfferType } from 'shared/types';

export interface ICreateOfferProperties {
  freezingPeriod: IFreezingPeriod;
  directCourse: string | undefined;
  reverseCourse: string | undefined;
  usedCourse: string | undefined;
  isReversedCourse: boolean;
  initial_crypto_amount: undefined | string;
  crypto_commission_amount: undefined | string;
  cash_min_amount: undefined | string;
  cash_max_amount: undefined | string;
  commission_percent: undefined | string;
  offer_type: TOfferType;
}

interface ICreateOfferState extends ICreateOfferProperties {
  changeFreezingPeriod: (
    fieldType: keyof IFreezingPeriod,
    newValue: string
  ) => void;
  changeCourse: (value: string | undefined) => void;
  changeType: (value: string) => void;
  changeReverseCourse: () => void;
  changeField: (
    field: keyof ICreateOfferProperties,
    newValue: string
  ) => string;
  resetState: () => void;
}

const initState: ICreateOfferProperties = {
  freezingPeriod: {
    minutes: 5,
    hours: 0,
    days: 0,
  },
  directCourse: undefined,
  reverseCourse: undefined,
  usedCourse: undefined,
  isReversedCourse: false,
  initial_crypto_amount: undefined,
  cash_min_amount: undefined,
  cash_max_amount: undefined,
  crypto_commission_amount: '0.00',
  commission_percent: '0.00',
  offer_type: 'cash',
};

export const useCreateOfferState = create<ICreateOfferState>()(
  devtools(
    (set, get) => ({
      ...initState,
      changeField: (field, newValue) => {
        let updatedValue: string;
        if (field === 'commission_percent') {
          updatedValue = validateNumericInput(newValue, 2, 4);
        } else if (DECIMAL_PART_EIGHT.includes(field)) {
          updatedValue = validateNumericInput(newValue);
        } else if (DECIMAL_PART_TWO.includes(field)) {
          updatedValue = validateNumericInput(newValue, 20, 2);
        } else {
          updatedValue = newValue;
        }
        set({ [field]: updatedValue });

        return updatedValue;
      },
      changeFreezingPeriod: (fieldType, newValue) => {
        const freezingPeriod = get().freezingPeriod;
        const validNewValue = parseInt(newValue.replace(/\D/g, '') || '0', 10);

        const getField = (type: keyof IFreezingPeriod) => {
          if (type === fieldType) {
            return validNewValue;
          } else {
            return freezingPeriod[type];
          }
        };

        let totalMinutes =
          getField('days') * 24 * 60 +
          getField('hours') * 60 +
          getField('minutes');

        if (totalMinutes < MIN_MINUTES) {
          totalMinutes = MIN_MINUTES;
        }

        if (totalMinutes > MAX_MINUTES_TOTAL) {
          totalMinutes = MAX_MINUTES_TOTAL;
        }

        const validatedDays = Math.floor(totalMinutes / (24 * 60));
        totalMinutes = totalMinutes % (24 * 60); // Оставшиеся минуты после подсчета дней

        const validatedHours = Math.floor(totalMinutes / 60);

        const validatedMinutes = totalMinutes % 60;

        set({
          freezingPeriod: {
            minutes: validatedMinutes,
            hours: validatedHours,
            days: validatedDays,
          },
        });
      },
      changeCourse: (newCourse) => {
        if (newCourse === undefined || newCourse === '') {
          set({
            directCourse: undefined,
            reverseCourse: undefined,
            usedCourse: undefined,
          });

          return;
        }

        let cleanedInput = validateNumericInput(
          newCourse,
          20,
          // потом мб вернем get().isReversedCourse ? 2 : 8
          8
        );

        let reverseCourse = BigNumber(1)
          .div(BigNumber(cleanedInput))
          // потом мб вернем get().isReversedCourse ? 2 : 8
          .toFixed(8);

        if (reverseCourse === 'Infinity') {
          reverseCourse = '0';
        }

        set(
          produce((draft) => {
            if (get().isReversedCourse) {
              draft.reverseCourse = cleanedInput;
              draft.directCourse = reverseCourse;
            } else {
              draft.directCourse = cleanedInput;
              draft.reverseCourse = reverseCourse;
            }
          })
        );

        set((state) => ({
          usedCourse: state.isReversedCourse
            ? state.reverseCourse
            : state.directCourse,
        }));
      },
      changeType: (newType: string) => {
        if (newType === undefined || newType === null) {
          return;
        }
        if (newType !== 'cash' && newType !== 'emoney') {
          return;
        }
        set({ offer_type: newType });
      },
      changeReverseCourse: () => {
        set((state) => ({
          isReversedCourse: !state.isReversedCourse,
        }));

        set((state) => ({
          usedCourse: state.isReversedCourse
            ? state.reverseCourse
            : state.directCourse,
        }));
      },
      resetState: () => {
        set({ ...initState });
      },
    }),
    {
      anonymousActionType: 'useCreateOfferState action',
      name: 'useCreateOfferState',
    }
  )
);
