import { axios } from 'shared/exios';
import { API_URL } from 'packages/keycloak-client/constants';
import { IMeta } from 'interfaces';
import { TOffersResponse } from 'shared/types';

export const getOffers = async (
  filters: [{ field: string; operator: string; value: string }],
  page?: number
): Promise<{
  data: TOffersResponse[];
  meta: IMeta;
}> => {
  try {
    const { data } = await axios.post<{
      data: TOffersResponse[];
      meta: IMeta;
    }>(`${API_URL}/exchange-point-offers/search`, {
      filters: filters,
      sort: [{ field: 'created_at', direction: 'desc' }],
      limit: 10,
      page: page ?? 1,
      includes: [
        {
          relation: 'requisites.currency',
        },
      ],
    });

    return {
      data: data.data,
      meta: data.meta,
    };
  } catch {
    return {
      data: [],
      meta: {} as IMeta,
    };
  }
};
