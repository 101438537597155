export { default as DEAL_STATUS } from './deal-status';

export const timezones = [
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+13:45',
  '+14:00',
  '-01:00',
  '-02:00',
  '-02:30',
  '-03:00',
  '-03:30',
  '-04:00',
  '-05:00',
  '-06:00',
  '-07:00',
  '-08:00',
  '-09:00',
  '-09:30',
  '-10:00',
  '-11:00',
];

export const YT_TEACHING = 'https://youtu.be/k7EAlx0W1fQ?si=g_akHUKX7-Gu3LBj';
export const YT_TEACHING_RU =
  'https://youtu.be/UfcGPpdXrU0?si=UzEH5ZJSj2PB6I23';
export const TG_SUPPORT = 'https://t.me/+izCXhGBxF0Y0ZGQ6';
