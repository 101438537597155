import { useTranslation } from 'react-i18next';
import { Link } from '@pankod/refine-react-router-v6';
import { Breadcrumb } from 'antd';
import { useMemo } from 'react';

interface IProps {
  isBuyingCrypto: boolean;
}
const BreadcrumbRequestPage = ({ isBuyingCrypto }: IProps) => {
  const { t, i18n } = useTranslation(['offers', 'shared']);
  const items = useMemo(
    () => [
      {
        title: <Link to="/offers">{t('Офферы и заявки')}</Link>,
        key: 'offers',
      },
      {
        title: isBuyingCrypto
          ? t('shared:тексты.Сделка на покупку n', { currency: 'USDT' })
          : t('shared:тексты.Сделка на продажу n', { currency: 'USDT' }),
        key: 'request',
      },
    ],
    [i18n.language, isBuyingCrypto]
  );

  return <Breadcrumb items={items} />;
};

export default BreadcrumbRequestPage;
