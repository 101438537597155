const DEAL_STATUS = {
  pending: 'pending',
  accepted: 'accepted',
  warrantyCollateral: 'warranty_collateral',
  waitingForFiatPayment: 'waiting_for_fiat_payment',
  ready: 'ready',
  completed: 'completed',
  expired: 'expired',
  onArgument: 'on_argument',
  cancelled: 'cancelled'
};

export default DEAL_STATUS;
