import { API_URL } from '../../../packages/keycloak-client/constants';
import { IOfferInfoResponse, IOfferInfoResponseNormalize } from '../model/types';
import { axios } from '../../../shared/exios';

// get offer
export const getOfferInfo = async (id: string): Promise<IOfferInfoResponseNormalize | undefined> => {
  try {
    const response = await axios.post(`${API_URL}/exchange-point-offers/search`, {
      filters: [
        {
            field: 'id',
            operator: '=',
            value: id
        }
      ],
      includes: [
        { relation: 'cash_payment_system_currency' },
        { relation: 'crypto_payment_system_currency' },
        { relation: 'exchange_point' },
        { relation: 'requisites.currency' },
      ]
    })

    const offerData: IOfferInfoResponse = response?.data?.data[0]

    return {
      id: offerData.id,
      exchangePointId: offerData?.exchange_point_id,
      name: offerData?.name,
      isBuyingCrypto: offerData?.is_buying_crypto,
      cryptoCurrencyCode: offerData?.crypto_currency_code,
      cashCurrencyCode: offerData?.cash_currency_code,
      initialCryptoAmount: offerData?.initial_crypto_amount,
      currentCryptoAmount: offerData?.current_crypto_amount,
      cashMinAmount: offerData?.cash_min_amount,
      cashMaxAmount: offerData?.cash_max_amount,
      exchangeRateSource: offerData?.exchange_rate_source,
      exchangeRate: offerData?.exchange_rate,
      cryptoCommissionAmount: offerData?.crypto_commission_amount,
      commissionPercent: offerData?.commission_percent,
      initialCommissionPercent: offerData?.initial_commission_percent,
      clientRate: offerData?.client_rate,
      status: offerData?.status,
      countryCode: offerData?.country_code,
      city: offerData?.city,
      createdAt: offerData?.created_at,
      updatedAt: offerData?.updated_at,
      dateDone: offerData?.date_done,
      dateDeclined: offerData?.date_declined,
      exchangeFreezeSeconds: offerData?.exchange_freeze_seconds,
      comment: offerData?.comment,
      cashPaymentSystemCurrency: {
        code: offerData?.cash_payment_system_currency?.code,
        currencyKey: offerData?.cash_currency_code,
        type: offerData?.cash_payment_system_currency?.type,
        paymentSystemName: offerData?.cash_payment_system_currency?.payment_system_name,
        useCount: offerData?.cash_payment_system_currency?.use_count,
        currencyType: offerData?.cash_payment_system_currency?.currency_type
      },
      cryptoPaymentSystemCurrency: {
        code: offerData?.crypto_payment_system_currency?.code,
        currencyKey: offerData?.crypto_payment_system_currency?.currency_key,
        type: offerData?.crypto_payment_system_currency?.type,
        paymentSystemName: offerData?.crypto_payment_system_currency?.payment_system_name,
        useCount: offerData?.crypto_payment_system_currency?.use_count,
        currencyType: offerData?.crypto_payment_system_currency?.currency_type
      },
      exchangePoint: offerData?.exchange_point,
      requisites: offerData?.requisites,
      type: offerData?.type
    }

  } catch (err) {
    console.log(err);
  }
};
