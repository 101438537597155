import styled from 'styled-components';
import { Card, Row, Typography } from '@pankod/refine-antd';
import { Tabs, Tag } from 'antd';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaymentMethodInfoWrapper = styled(Card)`
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  border-bottom: none;
  margin-top: 16px;

  & .ant-card-body {
    padding: 24px 0 0;
  }
`;

export const OffersTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 12px 24px;
    font-weight: 500;
  }

  .ant-tabs-nav {
    background-color: #ffffff;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: none;
    margin: 0;
  }
`;

export const PageCommonWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
`;

export const PaymentInfoWrapper = styled.div`
  width: 100%;
`;

export const PaymentInfoCard = styled.div`
  background-color: #ffffff;
  border-radius: 16px 16px 24px 24px;
  padding: 0 24px 24px 24px;
`;
export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  gap: 16px;
`;
export const TypographyTitle = styled(Typography.Title)`
  &&& {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
`;
export const FieldStyled = styled.span`
  color: #000000a6;
`;
export const GapedRow = styled(Row)`
  gap: 8px;
`;
export const CardWrapper = styled(Row)`
  gap: 32px;
`;
export const ColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const TextPopoverStyled = styled.p`
  max-width: 300px;
  word-break: break-word;
  color: #000000a6;
  background: transparent;
  margin: 0;
`;
export const StyledTag = styled(Tag)`
  height: fit-content;
`;
export const TableWrapper = styled.div`
  .ant-table-content table {
    border: none;
    border-radius: 0;
  }

  .ant-table {
    padding-top: 24px;
    background-color: #ffffff;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-radius: 0 0 16px 16px;
  }

  .ant-table-container table > thead > tr:first-child > :first-child {
    border-start-start-radius: 0;
  }

  .ant-table-container table > thead > tr:first-child > :last-child {
    border-start-end-radius: 0;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 16px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 16px;
  }

  .empty-table {
    background-color: #ffffff;
    padding-top: 10%;
    padding-bottom: 10%;
    margin: 0;
    border-radius: 0 0 16px 16px;
    border: 1px solid #f0f0f0;
  }
`;

export const LoaderWrapper = styled.div`
  padding: 10% 0;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 0 0 16px 16px;
`;
