import { UserAccountsRole } from 'interfaces';
import { useEffect, useState } from 'react';
import { useUserState } from 'shared/state/useUserState';

export const useUserRole = () => {
  const { userApi } = useUserState();

  const [userRole, setUserRole] = useState<UserAccountsRole>('cashier');

  useEffect(() => {
    if (userApi?.id && userApi?.role?.name) {
      setUserRole(userApi.role.name as UserAccountsRole);
    }
  }, [userApi?.id, userApi?.role?.name]);

  return userRole;
};
