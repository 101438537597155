import { Flex } from '../../../../shared/components/styled';
import {
  DatePicker,
  Popover,
  TimePicker,
  Typography,
} from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { useRequestConfirmState } from '../../model/useRequestConfirmState';
import { useMemo } from 'react';

const getMinimalDate = () => dayjs().add(5, 'minutes');

const disabledDate = (current: Dayjs) => {
  return current < dayjs().startOf('day');
};

const DateTimeBlock = () => {
  const { t, i18n } = useTranslation('shared');

  const offerRequestStaticData = useRequestConfirmState(
    (state) => state.offerRequestStaticData
  );

  const requestData = useRequestConfirmState((state) => state.requestData);
  const changeRequestDataField = useRequestConfirmState(
    (state) => state.changeRequestDataField
  );

  const exchangeDateTime = useMemo(() => {
    return requestData?.exchange_date
      ?.startOf('day')
      .add(requestData?.exchange_time.hour() || 0, 'hours')
      .add(requestData?.exchange_time.minute() || 0, 'minutes');
  }, [requestData?.exchange_time, requestData?.exchange_date]);

  const disabledTime = () => {
    const isSameDate = requestData?.exchange_date
      ?.startOf('day')
      .isSame(dayjs(Date.now()).startOf('day'));
    if (isSameDate) {
      const hoursArray = new Array(23).fill(0).map((_, index) => index);
      const minutesArray = new Array(59).fill(0).map((_, index) => index);
      return {
        disabledHours: () =>
          hoursArray.filter((hour) =>
            dayjs()
              .startOf('day')
              .add(hour + 1, 'hours')
              .isBefore(getMinimalDate())
          ),
        disabledMinutes: (selectedHour: number) =>
          minutesArray.filter((minute) =>
            dayjs()
              .startOf('day')
              .add(selectedHour, 'hours')
              .add(minute + 1, 'minutes')
              .isBefore(getMinimalDate())
          ),
      };
    }

    return {};
  };
  const handleChangeDate = (date: Dayjs | null) => {
    if (date) {
      changeRequestDataField('exchange_date', date);
    }

    if (
      dayjs(date)
        .add(requestData?.exchange_time?.hour() || 0, 'hours')
        .add(requestData?.exchange_time?.minute() || 0, 'minutes')
        .isBefore(getMinimalDate())
    ) {
      changeRequestDataField('exchange_time', getMinimalDate());
    }
  };

  const handleChangeTime = (time: Dayjs | null) => {
    if (
      time &&
      exchangeDateTime
        ?.startOf('day')
        .add(time?.hour() || 0, 'hours')
        .add(time?.minute() || 0, 'minutes')
        .isAfter(dayjs())
    ) {
      changeRequestDataField('exchange_time', time);
    }
  };

  const calculateFreezeTime = () => {
    const hasFreezeTime = offerRequestStaticData?.offer?.exchangeFreezeSeconds
      ? -offerRequestStaticData?.offer?.exchangeFreezeSeconds
      : 0;

    const freezeTime = exchangeDateTime
      ?.add(hasFreezeTime, 'seconds')
      .format('YYYY-MM-DD HH:mm:ss');

    if (dayjs().isAfter(freezeTime)) {
      return dayjs().format(format);
    }

    return exchangeDateTime?.add(hasFreezeTime, 'seconds').format(format);
  };

  const format = useMemo(
    () => `DD.MM.YYYY [${t('shared:тексты.в')}] HH:mm`,
    [i18n.language]
  );

  return (
    <Flex gap={16}>
      <Flex align={'flex-start'} gap={16} vertical>
        <DatePicker
          value={requestData?.exchange_date}
          onChange={handleChangeDate}
          disabledDate={disabledDate}
          allowClear={false}
          size={'small'}
        />
        <Flex align={'flex-start'} vertical>
          <Typography.Text type={'secondary'}>
            {`${t('shared:тексты.Заморозка гаранта')}: `}
          </Typography.Text>
          <Flex align={'center'} gap={8}>
            <Typography.Text>
              {(exchangeDateTime?.add(-2, 'hours').isBefore(dayjs())
                ? dayjs()
                : exchangeDateTime?.add(-2, 'hours')
              )?.format(format)}
            </Typography.Text>
            <Popover
              overlayInnerStyle={{ width: '265px' }}
              placement={'top'}
              color="#E5F9FF"
              content={
                <Flex align={'flex-start'} vertical>
                  <Typography.Text type={'secondary'}>
                    {offerRequestStaticData?.offer?.isBuyingCrypto
                      ? t(
                          'shared:тексты.Клиент сможет отметить заявку как просроченную и разморозить гарант'
                        )
                      : t(
                          'shared:тексты.Вы сможете отметить заявку как просроченную и разморозить гарант'
                        )}
                  </Typography.Text>
                  <Typography.Text>
                    {exchangeDateTime?.add(30, 'minutes').format(format)}
                  </Typography.Text>
                </Flex>
              }
            >
              <InfoCircleOutlined />
            </Popover>
          </Flex>
        </Flex>
      </Flex>

      <Flex align={'flex-start'} gap={16} vertical>
        <TimePicker
          value={requestData?.exchange_time}
          onChange={handleChangeTime}
          disabledTime={disabledTime}
          format={'HH:mm'}
          size={'small'}
          allowClear={false}
          showNow={false}
        />
        <Flex align={'flex-start'} vertical>
          <Typography.Text type={'secondary'}>
            {`${t('shared:тексты.Заморозка курса')}: `}
          </Typography.Text>
          <Typography.Text>{calculateFreezeTime()}</Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DateTimeBlock;
