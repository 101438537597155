import dayjs from 'dayjs';
import { useMemo } from 'react';
import { IInfoList } from '../types';
import { removeTrailingZeros } from '../../../../shared/helpers/removeTrailingZeros';
import { useTranslation } from 'react-i18next';
import { IRequest } from '../types';
import countryList from 'react-select-country-list';

type RequestStatus =
  | 'pending'
  | 'accepted'
  | 'cancelled'
  | 'warranty_collateral'
  | 'ready'
  | 'expired'
  | 'on_argument'
  | 'completed';

export const useFormattedClientRequest = (request: IRequest): IInfoList => {
  const { t, i18n } = useTranslation('shared');

  return useMemo(() => {
    const getCountry = (countryCode: string) =>
      countryList().data.find((country) => country.value === countryCode)
        ?.label;

    const initInfo: IInfoList = {
      items: [
        {
          name: 'cash',
          value: `${removeTrailingZeros(request.cash_exchange_point_amount)} ${
            request.offer?.cash_currency_code
          }`,
        },
        {
          label: t('shared:тексты.Клиент') + ':',
          pos: 1,
          value: `${!!request.client ? request.client.name : request.nickname}`,
          link: `${!!request.client ? `/clients/${request.client.id}` : ''}`,
        },
        {
          label: t('shared:тексты.Курс клиента двоеточие'),
          pos: 2,
          value: `${removeTrailingZeros(request.client_rate)} ${
            request.offer?.cash_currency_code
          }`,
        },
      ],
    };

    if (request.status !== 'pending' && request.status !== 'cancelled') {
      initInfo.items.push({
        label: t('shared:тексты.Дата обмена двоеточие'),
        pos: 5,
        value: dayjs(request.exchange_datetime).format('DD.MM.YYYY HH:mm'),
      });
    }

    if (request.offer.type === 'emoney') {
      initInfo.items.push({
        label: t('shared:тексты.Место обмена двоеточие'),
        pos: 6,
        value:
          getCountry(request.offer.country_code) || request.offer.country_code,
      });
      if (request?.requisites)
        initInfo.items.push({
          label: t('shared:тексты.Реквизиты') + ':',
          pos: 3,
          value: request?.requisites,
        });
      if (request?.full_name) {
        initInfo.items.push({
          label: t('shared:тексты.ФИО отправителя двоеточие'),
          pos: 4,
          value: request?.full_name,
        });
      }
    }

    return initInfo;
  }, [request, i18n.language]);
};
