import { AddressBlock } from './AddressBlock/AddressBlock';
import { CardStyled } from '../OfferRequestConfirm.styles';
import { useTranslation } from 'react-i18next';
import { Divider } from '@pankod/refine-antd';
import DateTimeBlock from './DateTimeBlock';
import InfoBlock from './InfoBlock';
import ReceiveCashBlock from './ReceiveCashBlock';
import FundsControlBlock from './FundsControlBlock';
import DatePlaceBlock from './DatePlaceBlock';
import { TOfferType } from 'shared/types';

type PropsType = {
  offerType: TOfferType;
  isBuyingCrypto: boolean;
};

const BottomInfoContainer = ({ offerType, isBuyingCrypto }: PropsType) => {
  const { t } = useTranslation(['offers', 'shared']);

  const getTitle = () => {
    if (offerType === 'cash' && isBuyingCrypto) {
      return t('shared:тексты.Откуда выдать наличные');
    }
    if (offerType === 'cash' && !isBuyingCrypto) {
      return t('shared:тексты.Как получить наличные');
    }
    if (offerType === 'emoney' && isBuyingCrypto) {
      return t('shared:тексты.Откуда выдать фиат');
    }
    if (offerType === 'emoney' && !isBuyingCrypto) {
      return t('shared:тексты.Как получить фиат');
    }
    return '';
  };

  return (
    <CardStyled>
      <InfoBlock title={getTitle()}>
        <ReceiveCashBlock />
      </InfoBlock>
      <Divider type={'vertical'} />
      {offerType === 'cash' ? (
        <InfoBlock title={t('shared:тексты.Дата обмена')}>
          <DateTimeBlock />
        </InfoBlock>
      ) : (
        <InfoBlock
          title={
            isBuyingCrypto
              ? t('shared:тексты.Куда перевести средства')
              : t('shared:тексты.Откуда будут получены средства')
          }
        >
          <FundsControlBlock isBuyingCrypto={isBuyingCrypto} />
        </InfoBlock>
      )}
      <Divider type={'vertical'} />
      {offerType === 'cash' ? (
        <InfoBlock title={t('shared:тексты.Место обмена')}>
          <AddressBlock />
        </InfoBlock>
      ) : (
        <InfoBlock title={t('shared:тексты.Дата и место обмена')}>
          <DatePlaceBlock />
        </InfoBlock>
      )}
    </CardStyled>
  );
};

export default BottomInfoContainer;
