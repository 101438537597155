import { API_URL } from 'packages/keycloak-client/constants';
import { IExchangePoint } from 'interfaces';
import { requestWithCache } from 'shared/helpers/requestWithCache';

type TPoints = {
  arr: IExchangePoint[];
  type: 'points';
};

type IExchangePointsResponse = {
  data: IExchangePoint[];
  meta: { total: number };
};

export const getExchangePoints = async (): Promise<TPoints> => {
  try {
    const res = await requestWithCache<IExchangePointsResponse>(
      `${API_URL}/exchange-points/search`,
      {
        method: 'POST',
        data: {
          limit: 1000,
          page: 1,
        },
      }
    );
    return {
      arr: res.data,
      type: 'points',
    };
  } catch {
    return { arr: [], type: 'points' };
  }
};
