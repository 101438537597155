import React, { useEffect, useState } from 'react';
import { Grid, Layout as AntdLayout } from 'antd';
import type { RefineLayoutLayoutProps } from '@pankod/refine-ui-types';
import { Header as DefaultHeader } from './header';
import { Sidebar } from './sidebar';
import { ExchangePointsProvider } from '../../contexts/exchange-points-context';
import { useResize } from '../../hooks/useResize';
import { useLocation } from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import CheckSession from '../../../app/CheckSession';
import { useCookiesCustom } from '../../hooks/useCookiesCustom';
import { useAuthState } from '../../state/useAuthState';
import { useChatTokensState } from '../../state/useChatTokensState';
import { CheckAccessToPage } from 'app/CheckAccessToPage';
import {
  InfoMessage,
  InfoWrapper,
  StyledAntdLayout,
  StyledDiv,
  WhiteLayout,
} from './layout.styles';

export const Layout: React.FC<
  RefineLayoutLayoutProps & {
    noPadding?: boolean;
  }
> = ({ children, Header, Sider, Footer, OffLayoutArea, noPadding = false }) => {
  const SiderToRender = () => {
    return <Sidebar />;
  };

  const HeaderToRender = Header ?? DefaultHeader;

  const { t, i18n } = useTranslation(['shared']);
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;
  const { isScreen1300 } = useResize();
  const [isPortrait, setIsPortrait] = useState(false);
  const { pathname } = useLocation();
  const { cookie } = useCookiesCustom();
  const getPhexChatToken = useChatTokensState(
    (state) => state.getPhexChatToken
  );
  // const getPartnerChatToken = useChatTokensState((state) => state.getPartnerChatToken);
  const refreshToken = useAuthState((state) => state.refreshToken);

  useEffect(() => {
    i18n.changeLanguage(cookie['i18nLang']);
  }, [cookie['i18nLang']]);

  const checkOrientation = () => {
    const isPortraitOrientation =
      window.screen.orientation.type === 'portrait-primary' ||
      window.screen.orientation.type === 'portrait-secondary';
    if (
      pathname.includes('/create-company') ||
      pathname.includes('/create-company-error') ||
      pathname.includes('/login') ||
      localStorage.getItem('creatingCompany') ||
      pathname === '/'
    ) {
      return;
    }
    setIsPortrait(isPortraitOrientation);
  };

  useEffect(() => {
    const updateChatTokenWorker = new Worker(
      new URL('../../workers/chatRefreshWorker', import.meta.url)
    );
    const accessRefreshWorker = new Worker(
      new URL('../../workers/accessRefreshWorker', import.meta.url)
    );

    getPhexChatToken().then();

    accessRefreshWorker.onmessage = () => {
      if (cookie['token']) {
        // @ts-ignore
        refreshToken({}).then();
      }
    };

    updateChatTokenWorker.onmessage = () => {
      if (cookie['phexChatToken']) {
        getPhexChatToken().then();
      }
      // if (cookie['partnerChatToken']) {
      //     getPartnerChatToken().then();
      // }
    };

    return () => {
      updateChatTokenWorker.terminate();
      accessRefreshWorker.terminate();
    };
  }, []);

  useEffect(() => {
    checkOrientation();
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [pathname]);

  return (
    <CheckSession>
      <CheckAccessToPage>
        {isPortrait && (
          <InfoWrapper>
            <InfoMessage>
              {t('shared:ошибки.Пожалуйста, измените ориентацию на альбомную')}
            </InfoMessage>
          </InfoWrapper>
        )}
        <ExchangePointsProvider>
          <StyledAntdLayout>
            <>{SiderToRender()}</>
            <WhiteLayout>
              <HeaderToRender />
              <AntdLayout.Content>
                <StyledDiv
                  noPadding={noPadding}
                  isSmall={isSmall}
                  isScreen1300={isScreen1300}
                >
                  {children}
                </StyledDiv>
                {OffLayoutArea && <OffLayoutArea />}
              </AntdLayout.Content>
              {Footer && <Footer />}
            </WhiteLayout>
          </StyledAntdLayout>
        </ExchangePointsProvider>
      </CheckAccessToPage>
    </CheckSession>
  );
};
