import { PopoverBlue } from './CreateOffer.styles';
import { TooltipPlacement } from 'antd/es/tooltip';

interface ITooltipLabel {
  tooltipText: string;
  placement?: TooltipPlacement;
  isHideTooltip?: boolean;
  children: JSX.Element;
}

export const CustomPopover = ({
  tooltipText,
  isHideTooltip,
  placement = 'topRight',
  children,
}: ITooltipLabel) => {
  if (isHideTooltip) {
    return <>{children}</>;
  }

  return (
    <PopoverBlue
      overlayInnerStyle={{ maxWidth: '320px' }}
      placement={placement}
      content={tooltipText}
    >
      {children}
    </PopoverBlue>
  );
};
