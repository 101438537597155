import { Flex } from '../../../../shared/components/styled';
import { Button, ButtonProps, Tooltip } from '@pankod/refine-antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { reorderArray } from '../../../../shared/helpers/reorderArray';
import { ACCOUNT_URL } from '../../../../packages/keycloak-client/constants';
import styled from 'styled-components';
import { DEAL_STATUS } from 'shared/constants';

interface IProps {
  transitionStatuses: string[];
  callBack: (trigger: string) => void;
  dealCurrentStatus: string;
  isBuyCrypto?: boolean;
  isCash?: boolean;
}

const idActionButtons = 'deals-cancel-tooltip';

const ActionsBtnBlock = ({
  transitionStatuses,
  callBack,
  dealCurrentStatus,
  isBuyCrypto,
  isCash,
}: IProps) => {
  const { t } = useTranslation(['offers', 'shared']);

  const getBtnIcon = (status: string): ButtonProps['icon'] => {
    switch (status) {
      case DEAL_STATUS.warrantyCollateral: {
        return <WalletOutlined style={{ color: '#00000073' }} />;
      }
      case DEAL_STATUS.ready:
      case DEAL_STATUS.completed: {
        return <CheckOutlined style={{ color: '#52c41a' }} />;
      }
      case DEAL_STATUS.onArgument: {
        return <ExclamationCircleOutlined style={{ color: '#faad14' }} />;
      }
      case DEAL_STATUS.expired:
      case DEAL_STATUS.cancelled: {
        return <CloseOutlined style={{ color: '#ff4d4f' }} />;
      }
      case DEAL_STATUS.accepted: {
        return <CheckOutlined style={{ color: '#52c41a' }} />;
      }
      default: {
        return undefined;
      }
    }
  };

  const onClickHandler = (trigger: string) => {
    callBack(trigger);
  };

  const onClickTopUpWallet = () => {
    window.open(ACCOUNT_URL + '/coin-accounts');
  };

  const ButtonCancelDisabled = () => {
    if (
      (dealCurrentStatus === 'waiting_for_fiat_payment' ||
        (dealCurrentStatus === 'ready' && !isBuyCrypto && isCash)) &&
      !transitionStatuses.includes(DEAL_STATUS.expired)
    ) {
      return (
        <Tooltip
          placement="topRight"
          title={t('Вы сможете отменить сделку через 30 минут')}
          getPopupContainer={() =>
            document.querySelector(`#${idActionButtons}`)!
          }
        >
          <ButtonDisabledStyled
            icon={<CloseOutlined style={{ color: '#00000040' }} />}
            type={'default'}
            className={'custom-button-grey-disable'}
          >
            {t('shared:кнопки.Отменить')}
          </ButtonDisabledStyled>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Flex gap={16} id={idActionButtons}>
      {dealCurrentStatus === DEAL_STATUS.warrantyCollateral && !isBuyCrypto && (
        <Button
          onClick={onClickTopUpWallet}
          icon={getBtnIcon(dealCurrentStatus)}
          type={'default'}
        >
          {t('shared:кнопки.Пополнить баланс')}
        </Button>
      )}
      {reorderArray(transitionStatuses)?.map((status) => {
        // skip rendering "payment sent" button if not buying
        if (status === DEAL_STATUS.ready && !isBuyCrypto) {
          return null;
        }

        return (
          <Button
            onClick={() => onClickHandler(status)}
            key={status}
            icon={getBtnIcon(status)}
            type={'default'}
          >
            {status === DEAL_STATUS.ready && (
              <span>{t('shared:кнопки.Отправил оплату')}</span>
            )}
            {status === DEAL_STATUS.onArgument && (
              <span>{t('Открыть спор')}</span>
            )}
            {status === DEAL_STATUS.completed && (
              <span>{t('Получил наличные')}</span>
            )}
            {status === DEAL_STATUS.expired ||
            status === DEAL_STATUS.cancelled ? (
              <span>
                {
                  /* TODO: Do we need nested t here? */ t(
                    `${
                      dealCurrentStatus === DEAL_STATUS.pending
                        ? t('Отклонить')
                        : t('shared:кнопки.Отменить')
                    }`
                  )
                }
              </span>
            ) : null}
            {status === DEAL_STATUS.accepted && (
              <span>{t('shared:кнопки.Принять')}</span>
            )}
          </Button>
        );
      })}

      <ButtonCancelDisabled />
    </Flex>
  );
};

export default ActionsBtnBlock;

const ButtonDisabledStyled = styled(Button)`
  &&&&.custom-button-grey-disable {
    background: #0000000a;
    color: #00000040;
    border: 1px solid #00000026;

    &:hover {
      background: #0000000a;
      cursor: not-allowed;
    }
  }
`;
