import {
  EmptyOfferTitleStyled,
  EmptyOfferTransactionsStyled,
  WrapperLoaderStyled,
  WrapperTableStyled,
} from '../../Offer.styles';
import { AdaptiveFullScreenLoader } from '../../../../../shared/components/full-screen-loader';
import DealsTable from '../../../../../features/DealsTable/ui/DealsTable';
import { BoxIcon } from '../../../../../shared/components/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TDeals } from '../../../../offers/model/types';
import { IExchangePoint } from '../../../../../interfaces';

interface IOfferTransactionsTabProps {
  isloading: boolean;
  deals: TDeals[];
  fetchDeals: () => void;
  pointsDeals: IExchangePoint[];
  handleChangeMetadata: (value: number) => void;
}

export const OfferTransactionsTab = (props:  IOfferTransactionsTabProps) => {

  const {isloading, deals, fetchDeals, handleChangeMetadata, pointsDeals} = props;

  const { t } = useTranslation(['shared']);

  return (
    <>
      {isloading ? (
        <WrapperLoaderStyled>
          <AdaptiveFullScreenLoader />
        </WrapperLoaderStyled>
      ) : !!deals.length ? (
        <WrapperTableStyled>

          <DealsTable
            refetchDeals={fetchDeals}
            setPagination={handleChangeMetadata}
            deals={deals}
            points={pointsDeals}
            isDealsEmpty={false}
            isFullTable={false}
          />
        </WrapperTableStyled>
      ) : (
        <EmptyOfferTransactionsStyled>
          <BoxIcon />
          <EmptyOfferTitleStyled>
            {t('shared:тексты.Нет заявок')}
          </EmptyOfferTitleStyled>
        </EmptyOfferTransactionsStyled>
      )}
    </>
  )
}
