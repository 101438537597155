import { Button } from '@pankod/refine-antd';
import { GridRowStyled } from './Offers.styles';
import { useTranslation } from 'react-i18next';
import { TNavigation } from '../model/types';
import { DownOutlinedStyled } from './NavigationTabs.styles';

interface NavigationTabsProps {
  isFiltersCollapsed: boolean;
  activeTab: TNavigation;
  setIsCollapsed: (value: boolean) => void;
  setActiveTab: (value: TNavigation) => void;
}

export const NavigationTabs = (props: NavigationTabsProps) => {
  const { setIsCollapsed, isFiltersCollapsed, activeTab, setActiveTab } = props;
  const { t } = useTranslation(['shared']);
  const handleCollapse = () => {
    setIsCollapsed(!isFiltersCollapsed);
  };

  return (
    <GridRowStyled>
      {activeTab === 'exchange' && (
        <Button
          onClick={handleCollapse}
          icon={
            <DownOutlinedStyled color={'rgba(0, 0, 0, 0.25)'} rotate={true} />
          }
          type="text"
        >
          {t('shared:тексты.Все фильтры')}
        </Button>
      )}
    </GridRowStyled>
  );
};
