import { axios } from '../../../../shared/exios';
import { API_URL } from '../../../../packages/keycloak-client/constants';
import { AxiosResponse } from 'axios';
import { FiltersType, PaymentMethods } from '../types';

export const getPaymentMethods = async (
  currency: string,
  searchValue?: string
) => {
  const filters: FiltersType[] = [
    {
      field: 'currency.currency_key',
      operator: '=',
      value: currency,
    },
  ];

  if (searchValue?.length) {
    filters.push({
      type: 'and',
      nested: [
        {
          type: 'or',
          field: 'requisites',
          operator: 'ilike',
          value: `%${searchValue}%`,
        },
        {
          type: 'or',
          field: 'currency.name',
          operator: 'ilike',
          value: `%${searchValue}%`,
        },
      ],
    });
  }

  try {
    const { data: paymentMethodsData } = await axios.post<
      AxiosResponse<PaymentMethods>
    >(`${API_URL}/offer-payment-requisites/search`, {
      // доработкой добавить инфинити скролл
      limit: 200,
      includes: [
        {
          relation: 'currency',
        },
      ],
      filters: filters,
    });
    return paymentMethodsData.data ?? [];
  } catch (error) {
    console.log(error);
  }
};
