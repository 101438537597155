import { ICreateOfferProperties } from './CreateOfferState';

export const DEFAULT_FIAT = {
  label: 'RUB',
  value: 'RUB',
};
export const DEFAULT_ACTIVE = {
  label: 'USDT',
  value: 'USDTTRC20',
};

export const DEFAULT_TYPE = {
  value: 'cash',
};

export const MIN_MINUTES = 5;
export const MAX_DAYS = 7;
export const MAX_MINUTES_TOTAL = MAX_DAYS * 24 * 60;

export const DECIMAL_PART_EIGHT: (keyof ICreateOfferProperties)[] = [
  'initial_crypto_amount',
  'crypto_commission_amount',
  'cash_min_amount',
  'cash_max_amount',
  'usedCourse',
];
export const DECIMAL_PART_TWO: (keyof ICreateOfferProperties)[] = [
  'commission_percent',
];
