import { TRequestForConfirmData } from '../model/useRequestConfirmState';
import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { IResponseShell } from '../../../shared/interfaces';
import { IOfferRequest } from '../../../shared/types/offers';

export enum EConfirmOfferRequestErrors {
  offerMustBeActive = 'Offer must be active',
  cryptoAmount = "Crypto amount mustn't exceed offer crypto_amount",
}

interface IConfirmOfferRequestError extends Error {
  errors: string[];
}

export class ConfirmOfferRequestError
  extends Error
  implements IConfirmOfferRequestError
{
  errors;
  constructor(errors: string[]) {
    super('Confirm request error');
    this.errors = errors;
  }
}

type requestConfirmData = Omit<
  TRequestForConfirmData,
  'exchange_date' | 'exchange_time'
> & { exchange_datetime: string };

export const confirmOfferRequest = async (
  requestConfirmData: requestConfirmData,
  requestId: number,
  offerType: string = 'cash'
) => {
  try {
    let payload = requestConfirmData;
    if (offerType === 'cash') {
      payload = {
        ...requestConfirmData,
        latitude: String(requestConfirmData.latitude),
        longitude: String(requestConfirmData.longitude),
      };
    }
    const { data } = await axios.put<IResponseShell<IOfferRequest>>(
      `${API_URL}/exchange-point-offer-requests/${requestId}/accept`,
      payload
    );
    return data.data;
  } catch (error: any) {
    const errors: string[] = [];

    if (error.response.data.errors.exchange_point_offer_id) {
      errors.push(...error.response.data.errors.exchange_point_offer_id);
    }
    if (error.response.data.errors.crypto_amount) {
      errors.push(...error.response.data.errors.crypto_amount);
    }

    if (errors.length) {
      throw new ConfirmOfferRequestError(errors);
    } else {
      throw new Error('Confirm request error');
    }
  }
};
