import styled from 'styled-components';
import { CSSProperties } from 'react';
import { Statistic, Typography } from '@pankod/refine-antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export const RequestInfoWrapper = styled.div`
  display: flex;

  gap: 24px;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 24px;
`;

export const RequestInfoHeader = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const Title = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;

export const ExclamationIconStyled = styled(ExclamationCircleOutlined)`
  color: rgba(250, 173, 20, 1);
  font-size: 16px;
`;

export const CloseIconStyled = styled(CloseOutlined)`
  color: rgba(255, 77, 79, 1);
  font-size: 16px;
`;

const { Countdown } = Statistic;

export const CountdownStyled = styled(Countdown)`
  .ant-statistic-content {
    font-size: 12px;
  }
  & .ant-statistic-content-suffix {
    font-weight: 600;
  }
  .ant-statistic-content-value {
    font-weight: 600;
  }
`;

export const TimerWrapper = styled.div`
  background: rgba(0, 0, 0, 0.02);
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;
