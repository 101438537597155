import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 12px;
  padding: 24px;
`;
export const ContainerHiddenStyled = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
`;
export const ButtonsContainerStyled = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  height: 60px;
  align-items: end;
`;
export const CheckboxesContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
`;
export const ResetContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
`;
