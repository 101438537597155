/**
 * Retrieves the currency icon for the given code.
 *
 * @param {string} code - The code of the currency.
 * @return {Promise<any | null>} A promise that resolves to the currency icon as an SVG or PNG image, or null if no icon is found.
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { EXCHANGE_MAP_API_URL } from '../../../../packages/keycloak-client/constants';

interface ICurrencyIconProps {
  code: string;
  heigth?: number;
  width?: number;
}

export const CurrencyIcon = ({
  code,
  heigth = 20,
  width = 20,
}: ICurrencyIconProps): JSX.Element => {
  const svgIconDynamic = `${EXCHANGE_MAP_API_URL.replace(
    'server',
    ''
  )}assets/currencies/${code}.svg`;
  const defaultIconDynamic = `${EXCHANGE_MAP_API_URL.replace(
    'server',
    ''
  )}assets/currencies/non_logo_currency.svg`;
  const [imgSrc, setImgSrc] = useState(svgIconDynamic);

  return (
    <ImageStyled
      $width={width}
      $height={heigth}
      src={imgSrc}
      alt={code}
      title={code}
      onError={(e) => setImgSrc(defaultIconDynamic)}
    />
  );
};

const ImageStyled = styled.img<{ $width: number; $height: number }>`
  border-radius: 50%;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background: white;
`;
