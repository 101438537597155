export enum ModalKeys {
  ModalChangeLanguage = 'ModalChangeLanguage',
  ModalSelectAddressOnMap = 'ModalSelectAddressOnMap',
  ModalOpenDispute = 'ModalOpenDispute',
  ModalCancelOffer = 'ModalCancelOffer',
  ModalCancelTransaction = 'ModalCancelTransaction',
  ModalConfirmReceiptOfCash = 'ModalConfirmReceiptOfCash',
  ModalCancelTransactionFromConfirm = 'ModalCancelTransactionFromConfirm',
  ModalAddPayment = 'ModalAddPayment',
  ModalConfirmPaymentSent = 'ModalConfirmPaymentSent',
}
