import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tooltip,
} from '@pankod/refine-antd';
import {
  ModalBodyStyled,
  ModalTitleStyled,
  OptionFillerStyled,
  RadioButtonStyled,
  RadioGroupStyled,
  SpanStyled,
  TextAreaStyled,
  TooltipTextStyled,
} from './ModalAddPayment.styles';
import { ModalKeys } from '../../model/enums';
import { useModalState } from '../../model/useModalState';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { BankOutlined, CheckCircleFilled } from '@ant-design/icons';
import { axios } from '../../../../../exios';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import { useUserState } from '../../../../../state/useUserState';
import { usePaymentCurrencies } from '../../../../../hooks/usePaymentCurrencies';
import { useIntersectionObserver } from '../../../../../hooks/useIntersectionObserver';

type TPaymentMethods = 'bank' | 'emoney';
type TPaymentTypes = 'account' | 'card_number' | 'phone_number';

export const ModalAddPayment = () => {
  const { t } = useTranslation();
  const [type, setType] = useState<TPaymentTypes>('account');
  const [offerPaymentCurrencyType, setOfferPaymentCurrencyType] =
    useState<TPaymentMethods>('bank');
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { userApi } = useUserState();
  const [form] = Form.useForm();

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalAddPayment).options
  );
  const closeModal = useModalState((state) => state.closeModal);
  const { currencies } = usePaymentCurrencies({
    type: offerPaymentCurrencyType,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const { data } = await axios.post(
            `${API_URL}/offer-payment-requisites`,
            Object.fromEntries(
              Object.entries({
                company_id: userApi.company_id,
                offer_payment_currency_id: form
                  .getFieldValue('offer_payment_currency_id')
                  .split('%')?.[0],
                type: form.getFieldValue('type'),
                requisites: form.getFieldValue('requisites'),
                internal_comment: form.getFieldValue('internal_comment'),
                comment: form.getFieldValue('comment'),
                offer_payment_currency: {
                  type: form.getFieldValue('offer_payment_currency_type'),
                },
              }).filter(([_, value]) => value != null)
            )
          );
          notification.success({
            icon: (
              <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />
            ),
            message: t('shared:тексты.Способ оплаты создан'),
            description: t(
              `shared:тексты.Теперь вы можете использовать его в своих офферах`
            ),
            style: { borderLeft: '6px solid rgba(19, 194, 194, 1)' },
          });
          modalOptions?.callback();
          handleCloseModal();
        } catch (e) {
          if (
            (e as { message: string }).message ===
            'Offer payment requisite already exists.'
          ) {
            setTooltipMessage(
              t(
                'shared:ошибки.Невозможно создать способ оплаты — такой способ оплаты уже существует в вашей компании'
              )
            );
            setIsButtonDisabled(true);
            setOpen(true);
          }
          notification.error({
            duration: 5,
            message: t('shared:тексты.Ошибка запроса'),
            description: t(
              'shared:ошибки.Произошла ошибка при выполнении запроса точка Пожалуйста запятая попробуйте повторить запрос позже точка'
            ),
            style: { borderLeft: '6px solid #FF4D4F' },
          });
        }
      })
      .catch((e) => {
        const hasErrors = form
          .getFieldsError()
          .some(({ errors }) => errors.length > 0);
        const { comment, internal_comment, ...fields } = form.getFieldsValue();
        const isEmpty = Object.values(fields).some(
          (value) => value === '' || value === null || value === undefined
        );
        setIsButtonDisabled(hasErrors || isEmpty);
        if (hasErrors || isEmpty) {
          setTooltipMessage(
            t(
              'shared:ошибки.Невозможно создать способ оплаты — некорректное заполнение полей'
            )
          );
        } else {
          setTooltipMessage(null);
        }
      });
  };
  const handleFormChange = () => {
    const isTouched = form.isFieldsTouched();
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    setIsButtonDisabled(hasErrors && isTouched);
    setTooltipMessage(null);
  };
  const handleModeChange = (e: RadioChangeEvent) => {
    form.resetFields(['requisites', 'type', 'offer_payment_currency_id']);
    setOfferPaymentCurrencyType(e.target.value);
  };

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalAddPayment)
  );
  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalAddPayment);
  };

  const options = currencies.map((item) => (
    <Select.Option
      key={item.id}
      value={item.id + '%' + item.name + ' ' + item.currency_key}
      label={item.name + ' - ' + item.currency_key}
    >
      <OptionFillerStyled>
        <span>{item.name}</span>
        <SpanStyled>{item.currency_key}</SpanStyled>
      </OptionFillerStyled>
    </Select.Option>
  ));
  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      width={636}
      closable={true}
      centered
      footer={[
        <Button key="cancel" onClick={handleCloseModal}>
          {t('shared:кнопки.Отмена')}
        </Button>,
        <Tooltip
          title={
            tooltipMessage ? (
              <TooltipTextStyled>{tooltipMessage}</TooltipTextStyled>
            ) : (
              tooltipMessage
            )
          }
          color="#FFF1F0"
          placement="top"
        >
          <Button
            key="submit"
            disabled={isButtonDisabled}
            type="primary"
            onClick={handleSubmit}
          >
            {t('shared:кнопки.Создать')}
          </Button>
        </Tooltip>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ offer_payment_currency_type: 'bank' }}
        onFieldsChange={handleFormChange}
      >
        <ModalTitleStyled>
          {t('shared:тексты.Создать способ оплаты')}
        </ModalTitleStyled>
        <Form.Item
          name="offer_payment_currency_type"
          label={t('shared:тексты.Тип способа оплаты')}
          rules={[
            {
              required: true,
              message: '' + t('shared:тексты.Выберите тип способа оплаты'),
            },
          ]}
        >
          <RadioGroupStyled
            onChange={handleModeChange}
            value={offerPaymentCurrencyType}
          >
            <RadioButtonStyled value="bank">
              {t('shared:типы.Счёт в банке')}
            </RadioButtonStyled>
            <RadioButtonStyled value="emoney">
              {t('shared:типы.Электронный кошелёк')}
            </RadioButtonStyled>
          </RadioGroupStyled>
        </Form.Item>
        {offerPaymentCurrencyType === 'bank' && (
          <>
            <Form.Item
              name="offer_payment_currency_id"
              label={t('shared:тексты.Банк и валюта')}
              rules={[
                {
                  required: true,
                  message: '' + t('shared:тексты.Выберите банк и валюту'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={
                  <>
                    <BankOutlined type="search" /> &nbsp;{' '}
                    {t('shared:тексты.Выберите банк и валюту')}
                  </>
                }
                optionLabelProp="label"
              >
                {options}
              </Select>
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label={t('shared:тексты.Тип реквизитов')}
                  rules={[
                    {
                      required: true,
                      message: '' + t('shared:тексты.Выберите тип реквизитов'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('shared:тексты.Выберите тип реквизитов')}
                  >
                    <Select.Option value="account">
                      {t('shared:тексты.Счёт')}
                    </Select.Option>
                    <Select.Option value="card_number">
                      {t('shared:тексты.Карта')}
                    </Select.Option>
                    <Select.Option value="phone_number">
                      {t('shared:тексты.Номер телефона')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="requisites"
                  label={t('shared:тексты.Номер реквизитов')}
                  rules={[
                    {
                      required: true,
                      message: '' + t('shared:тексты.Введите номер реквизитов'),
                    },
                    {
                      max: 255,
                      message: '' + t('shared:тексты.Не более 255 символов'),
                    },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.resolve(); // Для случаев, когда поле уже помечено как "required"
                        }
                        if (/\s/.test(value)) {
                          return Promise.reject(
                            t(
                              'shared:тексты.Разрешенно вводить только буквы и числа'
                            )
                          );
                        }
                        if (value.length < 3) {
                          return Promise.reject(
                            t('shared:тексты.Введите номер реквизитов')
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      '' + t('shared:тексты.Введите номер реквизитов')
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {offerPaymentCurrencyType === 'emoney' && (
          <>
            <Form.Item
              name="offer_payment_currency_id"
              label={t('shared:тексты.Электронный кошелёк и валюта')}
              rules={[
                {
                  required: true,
                  message: '' + t('shared:тексты.Электронный кошелёк и валюта'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t(
                  'shared:тексты.Выберите электронный кошелёк и валюту'
                )}
                optionLabelProp="label"
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              name="requisites"
              label={t('shared:тексты.Номер реквизитов')}
              rules={[
                {
                  required: true,
                  message: '' + t('shared:тексты.Введите номер реквизитов'),
                },
                {
                  max: 255,
                  message: '' + t('shared:тексты.Не более 255 символов'),
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve(); // Для случаев, когда поле уже помечено как "required"
                    }
                    if (/\s/.test(value)) {
                      return Promise.reject(
                        t(
                          'shared:тексты.Разрешенно вводить только буквы и числа'
                        )
                      );
                    }
                    if (value.length < 3) {
                      return Promise.reject(
                        t('shared:тексты.Введите номер реквизитов')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder={'' + t('shared:тексты.Введите номер реквизитов')}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={`${t('shared:тексты.Комментарий для клиентов')}`}
          name="comment"
        >
          <TextAreaStyled
            showCount
            maxLength={5000}
            placeholder={'' + t('shared:тексты.Введите комментарий')}
          />
        </Form.Item>
        <Form.Item
          label={`${t('shared:тексты.Комментарий для сотрудников')}`}
          name="internal_comment"
        >
          <TextAreaStyled
            showCount
            maxLength={5000}
            placeholder={'' + t('shared:тексты.Введите комментарий')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
