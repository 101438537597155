import { Modal } from "@pankod/refine-antd"
import { CurrencyIcon } from "shared/components/ui/CurrencyIcon"
import styled from "styled-components"

export const ModalStyled = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      margin-bottom: 26px;
      padding-right: 26px;
    }

    .ant-modal-title {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      font-family: SF Pro Text, sans-serif;
    }

    .ant-modal-close {
      top: 32px;
      right: 32px;
    }

    .ant-modal-body {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: #1f1f1f;
      font-family: SF Pro Text, sans-serif;
    }

    .ant-modal-footer .ant-btn {
      padding: 8px 16px;
      height: 40px;
      font-size: 16px;
      line-height: 22px;
    }
  }
`

export const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 12px;
  gap: 16px;
`

export const SectionLabel = styled.div`
  margin-bottom: 4px;
  color: #565656;
`

export const SectionInfo = styled.div`
  color: #1d1d1d;

  .anticon {
    margin-right: 8px;
  }
`

export const CurrencyIconStyled = styled(CurrencyIcon)`
  margin-right: 8px;
`