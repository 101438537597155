import { useUserRole } from '../shared/hooks/useUserRole';
import { UserAccountsRole } from 'interfaces';
import { useNavigation } from '@pankod/refine-core';
import { ROLES_ALLOWED_ROUTES } from 'shared/constants/rolesAllowedRoutes';

export const CheckAccessToPage = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { replace } = useNavigation();
  const userRole = useUserRole();
  const pathname = window.location.pathname;

  const userAllowedRoutes =
    ROLES_ALLOWED_ROUTES[userRole as UserAccountsRole] || [];
  const isAllowed = userAllowedRoutes.some((route) =>
    pathname.startsWith(route)
  );
  if (!isAllowed) {
    replace('/accounts');
    return null;
  }

  return <>{children}</>;
};
