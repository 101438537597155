import { axios } from 'shared/exios';
import { API_URL } from 'packages/keycloak-client/constants';

import { IResponseShell, IMeta } from 'shared/interfaces';
import { TDeals } from 'shared/types';

export const getDeals = async (
  filters?: [{ field: string; operator: string; value: string }],
  page?: number
): Promise<{ data: TDeals[]; meta: IMeta }> => {
  try {
    const { data } = await axios.post<IResponseShell<TDeals[]>>(
      `${API_URL}/exchange-point-offer-requests/search`,
      {
        filters: filters,
        limit: 10,
        page: page ?? 1,
        includes: [
          { relation: 'offer' },
          { relation: 'offer.exchange_point.company' },
          { relation: 'requisite.currency' },
        ],
        sort: [{ field: 'created_at', direction: 'desc' }],
      }
    );
    return { data: data?.data, meta: data.meta };
  } catch {
    return {
      data: [],
      meta: {} as IMeta,
    };
  }
};
