import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { create } from 'zustand';
import { ModalKeys } from './enums';
interface IModalItemState {
  key: string;
  options: any;
  isOpen: boolean;
}

type TModalState = {
  modals: { [key: string]: IModalItemState };
  openModal: (key: string, options?: object | null) => void;
  closeModal: (key: string) => void;
  isModalOpen: (key: string) => boolean;
  getModal: (key: ModalKeys) => IModalItemState;
  getModals: () => void;
};

export const useModalState = create<TModalState>()(
  devtools(
    (set, get) => ({
      modals: {
        [ModalKeys.ModalCancelOffer]: {
          key: ModalKeys.ModalCancelOffer,
          options: {
            id: 0,
            isBuyingCrypto: false,
            cryptoCurrencyCode: '',
            callback: () => undefined,
          },
          isOpen: false,
        },
        [ModalKeys.ModalConfirmReceiptOfCash]: {
          key: ModalKeys.ModalConfirmReceiptOfCash,
          options: {
            id: 0,
            isBuyingCrypto: false,
            name: '',
          },
          isOpen: false,
        },
        [ModalKeys.ModalConfirmPaymentSent]: {
          key: ModalKeys.ModalConfirmPaymentSent,
          options: {
            id: 0,
          },
          isOpen: false,
        },
        [ModalKeys.ModalCancelTransaction]: {
          key: ModalKeys.ModalCancelTransaction,
          options: {
            id: 0,
            isBuyingCrypto: false,
            name: '',
            withAllowedComment: false,
          },
          isOpen: false,
        },
        [ModalKeys.ModalSelectAddressOnMap]: {
          key: ModalKeys.ModalSelectAddressOnMap,
          options: {
            onSubmit: (arr: [number, number]): void => {},
            currentPoint: undefined,
          },
          isOpen: false,
        },
      },
      openModal: (key, options) =>
        set(
          produce((draft) => {
            draft.modals[key] = { ...draft.modals[key], isOpen: true, options };
          }),
          false,
          {
            type: 'useModalState => openModal',
          }
        ),
      closeModal: (key) =>
        set(
          produce((draft) => {
            draft.modals[key] = {
              ...draft.modals[key],
              isOpen: false,
              options: null,
            };
          }),
          false,
          {
            type: 'useModalState => closeModal',
          }
        ),
      isModalOpen: (key) => get().modals[key]?.isOpen,
      getModal: (key: ModalKeys) => get().modals[key],
      getModals: () => get().modals,
    }),
    {
      name: 'useModalState',
      anonymousActionType: 'useModalState action',
    }
  )
);
