import { useMemo } from 'react';
import { t } from 'i18next';
import { EExchangeRateSource } from 'shared/enums';
import { IInfoList, IRequest } from '../types';
import i18n from 'app/i18n';
import { useTranslation } from 'react-i18next';

const useFormattedGeneralRequestInfo = (request: IRequest): IInfoList => {
  const { t, i18n } = useTranslation('shared');

  const formatDuration = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    return `${days}${t('shared:тексты.д')} : ${hours
      .toString()
      .padStart(2, '0')}${t('shared:тексты.ч')} : ${minutes
      .toString()
      .padStart(2, '0')}${t('shared:тексты.м')}`;
  };

  const items = useMemo(() => {
    const itemsList = [
      {
        label: t('shared:тексты.Источник курса двоеточие'),
        value: EExchangeRateSource[request.offer?.exchange_rate_source],
        pos: 1,
      },
      {
        label: t('shared:тексты.Курс без комиссии двоеточие'),
        pos: 2,
        value: `${request.exchange_rate} ${request.offer?.cash_currency_code}`,
      },
      {
        label: t('shared:тексты.Срок заморозки курса двоеточие'),
        pos: 3,
        value: formatDuration(request.offer?.exchange_freeze_seconds),
      },
      {
        label: t('shared:тексты.Cделка двоеточие'),
        pos: 5,
        value: request?.name,
      },
      {
        label: t('shared:тексты.Оффер двоеточие'),
        pos: 6,
        value: request.offer?.name,
        link: `/offers/${request.offer?.id}`,
      },
    ];

    if (request?.operations_chain?.id) {
      itemsList.push({
        label: `${t('shared:тексты.Операция')}:`,
        pos: 7,
        value: `${request?.operations_chain?.id}`,
        link: `/chains/${request?.operations_chain?.id}`,
      });
    }

    return itemsList;
  }, [request, t, i18n]);

  return { items };
};

export default useFormattedGeneralRequestInfo;
