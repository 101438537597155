import React, { CSSProperties, useState } from 'react';
import { t } from 'i18next';
import { TextPopoverStyled } from './PaymentMethodPage.styles';
import { Popover } from '@pankod/refine-antd';

export const TruncatedText = ({
  text,
  maxCommentLength = 100,
  canOpen = true,
  popoverText,
}: {
  text: string;
  maxCommentLength: number;
  canOpen?: boolean;
  popoverText?: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const isTruncated = text.length > maxCommentLength;

  const toggleExpanded = () => setExpanded((prev) => !prev);
  const truncatedTextStyle: CSSProperties = {
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    margin: 0,
  };
  return (
    <span style={truncatedTextStyle}>
      {popoverText && maxCommentLength < text?.length ? (
        <Popover
          content={
            <TextPopoverStyled>
              {popoverText || t('shared:статусы.Нет данных')}
            </TextPopoverStyled>
          }
          trigger="hover"
          color="#E5F9FF"
        >
          {expanded || !isTruncated
            ? text
            : `${text.slice(0, maxCommentLength)}...`}{' '}
        </Popover>
      ) : (
        <>
          {expanded || !isTruncated
            ? text
            : `${text.slice(0, maxCommentLength)}...`}{' '}
        </>
      )}

      {isTruncated && canOpen && (
        <span
          style={{ cursor: 'pointer', color: '#1677FF' }}
          onClick={toggleExpanded}
        >
          {expanded ? t('shared:тексты.Свернуть') : t('shared:тексты.Ещё')}
        </span>
      )}
    </span>
  );
};
