import { Link, useParams } from '@pankod/refine-react-router-v6';
import React, { useEffect, useState } from 'react';
import { getOfferInfo } from '../api/getOfferInfo';
import { IOfferInfoResponseNormalize } from '../model/types';
import { useOfferInfoState } from '../model/useOfferInfoState';
import { Tag } from 'antd';
import {
  ActiveRowStyled,
  ButtonStyled, NoWrapRowStyled,
  OfferActionButtonStyled,
  OfferBodyStyled,
  OfferColumnStyled,
  OfferCommentRowStyled,
  OfferHeaderInformationStyled,
  OfferHeaderStyled,
  OfferIndexStyled,
  OfferRowStyled,
  OfferTitleStyled,
} from './Offer.styles';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { formatSecondsToTime } from '../../../shared/helpers/formatSecondsToTime';
import { CountryFlag } from '../../../shared/components/CountryFlag';
import { useModalState } from '../../../shared/components/ui/RenderModal/model/useModalState';
import { ModalKeys } from '../../../shared/components/ui/RenderModal/model/enums';
import { RenderModal } from '../../../shared/components/ui/RenderModal';
import { removeTrailingZeros } from '../../../shared/helpers/removeTrailingZeros';

// вся инфа об оффере, не включая табы (сделки)
const OfferInfo = () => {
  const { t } = useTranslation(['shared']);

  let { id } = useParams();

  const {
    offerInfo,
    setOfferInfo,
    isBuyingCrypto,
    setIsBuyingCrypto,
    offerStatus,
    setOfferStatus,
  } = useOfferInfoState();

  const exchangeFreezeSecondsFormat = formatSecondsToTime(
    offerInfo?.exchangeFreezeSeconds ?? 0
  );

  useEffect(() => {
    id &&
      getOfferInfo(id).then((data) => {
        if (data) {
          return setOfferInfo(data);
        }
      });
  }, []);

  useEffect(() => {
    if (offerInfo?.id) {
      setIsBuyingCrypto(offerInfo?.isBuyingCrypto);
      setOfferStatus(offerInfo?.status);
    }
  }, [offerInfo?.id]);
  const { openModal } = useModalState();
  const handleOpenModalCancel = (
    id?: number,
    isBuyingCrypto?: boolean,
    cryptoCurrencyCode?: string
  ) => {
    openModal(ModalKeys.ModalCancelOffer, {
      id: id,
      isBuyingCrypto: isBuyingCrypto,
      cryptoCurrencyCode: cryptoCurrencyCode,
      callback: () => setOfferStatus('declined'),
    });
  };
  const handleClick = () => {
    handleOpenModalCancel(
      offerInfo?.id,
      offerInfo?.isBuyingCrypto,
      offerInfo?.cryptoPaymentSystemCurrency.currencyKey
    );
  };

  return (
    <>
      <OfferHeaderStyled>
        <OfferTitleStyled>
          {isBuyingCrypto
            ? t('shared:тексты.Оффер на покупку USDT за n m', {
                n:
                  offerInfo?.type === 'cash'
                    ? t('shared:тексты.наличные')
                    : t('shared:тексты.безналичные'),
                m: offerInfo?.cashCurrencyCode,
              })
            : t('shared:тексты.Оффер на продажу USDT за n m', {
                n:
                  offerInfo?.type === 'cash'
                    ? t('shared:тексты.наличные')
                    : t('shared:тексты.безналичные'),
                m: offerInfo?.cashCurrencyCode,
              })}
        </OfferTitleStyled>
        {offerStatus === 'active' && (
          <Tag color={'blue'}>{t('shared:тексты.Активен')}</Tag>
        )}
        {offerStatus === 'done' && (
          <Tag color={'cyan'}>{t('shared:тексты.Закрыт')}</Tag>
        )}
        {offerStatus === 'declined' && (
          <Tag color={'red'}>{t('shared:тексты.Отменен')}</Tag>
        )}
        {(offerStatus === 'active' || offerStatus === 'done') && (
          <OfferActionButtonStyled onClick={handleClick}>
            <CloseOutlined style={{ color: '#FF4D4F' }} />{' '}
            {t('shared:кнопки.Отменить')}
          </OfferActionButtonStyled>
        )}
      </OfferHeaderStyled>
      <OfferIndexStyled>{offerInfo?.name}</OfferIndexStyled>
      <OfferBodyStyled>
        <OfferColumnStyled>
          <ActiveRowStyled>
            <div>{t('shared:тексты.Актив двоеточие')}</div>
            <div>
              <b>
                {removeTrailingZeros(offerInfo?.currentCryptoAmount)}
                {' '}
              </b>
              <NoWrapRowStyled>
                {t('shared:тексты.из')}{' '}
                {offerInfo?.initialCryptoAmount}
                {' '}
                {offerInfo?.cryptoPaymentSystemCurrency?.currencyKey}
              </NoWrapRowStyled>
            </div>
          </ActiveRowStyled>
        </OfferColumnStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Курс клиента двоеточие')}</div>
            <b>
              {removeTrailingZeros(offerInfo?.clientRate)}{' '}
              {offerInfo?.cashCurrencyCode}
            </b>
          </OfferRowStyled>
        </OfferColumnStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Срок заморозки курса двоеточие')}</div>
            <Tag>
              {exchangeFreezeSecondsFormat.dDisplay +
                t('shared:тексты.дней двоеточие') +
                exchangeFreezeSecondsFormat.hDisplay +
                t('shared:тексты.часов двоеточие') +
                exchangeFreezeSecondsFormat.mDisplay +
                t('shared:тексты.минут сокращенное')}
            </Tag>
          </OfferRowStyled>
        </OfferColumnStyled>
      </OfferBodyStyled>
      <RenderModal currentModalKey={ModalKeys.ModalCancelOffer} />
    </>
  );
};
export default OfferInfo;
