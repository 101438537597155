import { useTranslation } from 'react-i18next';
import { Link } from '@pankod/refine-react-router-v6';
import { Breadcrumb } from 'antd';

interface IProps {
  name: string;
}
export const BreadcrumbsPaymentMethodPage = ({ name }: IProps) => {
  const { t } = useTranslation('shared');

  const items = [
    {
      title: (
        <Link to="/payment-methods">{t('shared:тексты.Способы оплаты')}</Link>
      ),
      key: 'paymentMethods',
    },
    {
      title: name,
      key: 'payment',
    },
  ];

  return <Breadcrumb items={items} />;
};
