import { Typography } from '@pankod/refine-antd';
import { RequestInfoItemWrapper } from '../OfferRequestPage.styles';
import { ReactNode } from 'react';

interface IProps {
  title: string;
  value: ReactNode;
  strong?: boolean;
}

const RequestInfoItem = ({ title, value, strong }: IProps) => {
  return (
    <RequestInfoItemWrapper gap={4} align={'flex-start'} vertical>
      <Typography.Text type="secondary">{title}</Typography.Text>
      {typeof value === 'string' ? (
        <Typography.Text strong={strong}>{value}</Typography.Text>
      ) : (
        value
      )}
    </RequestInfoItemWrapper>
  );
};

export default RequestInfoItem;
