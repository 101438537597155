import {axios} from "../../../shared/exios";
import {API_URL} from "../../../packages/keycloak-client/constants";
import { TOffers, TOffersData, TOffersResponse} from "../model/types";
import {IMeta} from "../../../interfaces";

function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, match => match[1].toUpperCase());
}

function toCamelCaseArray(arr: TOffersResponse[]): TOffers[] {
  return arr.map(obj => {
    const newObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelKey = snakeToCamel(key);
        newObj[camelKey] = obj[key as keyof TOffersResponse]; // Здесь мы не углубляемся внутрь значений
      }
    }
    return newObj;
  });
}

export const getOffers = async (filters?: [], page?: number): Promise<TOffersData> => {
  try {
    const {data} = await axios.post<{
      data: TOffersResponse[];
      meta: { total: number }
    }>(`${API_URL}/exchange-point-offers/search`, {
      filters: filters,
      sort: [{ field: 'created_at', direction: 'desc' }],
      limit: 10,
      page: page ?? 1,
      includes: [
        {
          relation: "requisites.currency"
        }
      ]
    });
    const normalizedData: TOffersData = {data: toCamelCaseArray(data.data), meta: data.meta as IMeta}
    return normalizedData
  } catch {
    return {
      data: [],
      meta: {} as IMeta
    }
  }
}
