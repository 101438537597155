import OfferInfo from './OfferInfo';
import { Link } from '@pankod/refine-react-router-v6';
import { OfferBreadcrumbStyled, OfferContainerStyled } from './Offer.styles';
import { useTranslation } from 'react-i18next';
import { useOfferInfoState } from '../model/useOfferInfoState';
import OfferTabs from './OfferTabs/OfferTabs';
import React from 'react';

const OfferPage = () => {
  const { t } = useTranslation(['shared']);


  const { offerInfo,  isBuyingCrypto } =
    useOfferInfoState();

  const breadcrumbItems = [
    {
      title: <Link to="/offers">{t('shared:тексты.Офферы и Сделки')}</Link>,
      key: 'offers',
    },
    {
      title: isBuyingCrypto
        ? t('shared:тексты.Оффер на покупку USDT за n m',
          {
            n: offerInfo?.type === 'cash' ? t('shared:тексты.наличные') : t('shared:тексты.безналичные'),
            m: offerInfo?.cashCurrencyCode
          })
        : t('shared:тексты.Оффер на продажу USDT за n m',
          {
            n: offerInfo?.type === 'cash' ? t('shared:тексты.наличные') : t('shared:тексты.безналичные'),
            m: offerInfo?.cashCurrencyCode
          }),
      key: 'offer',
    },
  ];

  return (
    <>
      <OfferBreadcrumbStyled items={breadcrumbItems} />
      <OfferContainerStyled>
        <OfferInfo />
      </OfferContainerStyled>
      <OfferTabs />
    </>
  );
};
export default OfferPage;
