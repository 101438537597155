import { DatesInfoWrapper } from '../../../OfferRequestPage.styles';
import DateInfoItem from './DateInfoItem';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../../../../shared/components/styled';
import { Typography } from '@pankod/refine-antd';

interface IProps {
  dateCreated: string;
  dateFreezeExchange?: string;
  dateFreezeRate?: string;
  dateOverdue?: string;
}

const DatesInfo = ({
  dateCreated,
  dateFreezeExchange,
  dateFreezeRate,
  dateOverdue,
}: IProps) => {
  const { t } = useTranslation('shared');
  return (
    <DatesInfoWrapper>
      <DateInfoItem
        label={t('shared:тексты.Дата создания двоеточие')}
        value={dateCreated}
      />
      {dateFreezeExchange && (
        <DateInfoItem
          label={t('shared:тексты.Дата заморозки гаранта двоеточие')}
          value={dateFreezeExchange}
          popoverText={
            <Flex gap={4} align={'flex-start'} vertical>
              <Typography.Text type={'secondary'}>
                {t(
                  'shared:тексты.Вы сможете отметить заявку как просроченную и разморозить гарант'
                )}
              </Typography.Text>
              <Typography.Text>{dateOverdue}</Typography.Text>
            </Flex>
          }
        />
      )}
      {dateFreezeRate && (
        <DateInfoItem
          label={t('shared:тексты.Дата заморозки курса двоеточие')}
          value={dateFreezeRate}
        />
      )}
    </DatesInfoWrapper>
  );
};

export default DatesInfo;
