import { useEffect, useRef, useState } from 'react';
import { axios } from '../exios';
import { IResponseShell } from '../interfaces';
import { API_URL } from '../../packages/keycloak-client/constants';
import { TRequisite } from '../types';
export interface PaymentRequisitesProps {
  filters?: {
    operator: string;
    field: string;
    value: string | number;
  }[];
}
export const usePaymentRequisites = (payload: PaymentRequisitesProps = {}) => {
  const { filters } = payload;
  const prevFilters = useRef<string>('');
  const [requisites, setRequisites] = useState<TRequisite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post<IResponseShell<TRequisite[]>>(
        `${API_URL}/offer-payment-requisites/search`,
        {
          filters: filters ?? [],
          includes: [
            {
              relation: 'country',
            },
            {
              relation: 'currency',
            },
            {
              relation: 'offers',
            },
            {
              relation: 'requests',
            },
          ],
        }
      );
      setRequisites(data.data);
    } catch (e) {
      console.error(e);
      setRequisites([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (filters) {
      const filtersValue = filters
        .sort()
        .map((f) => f.value)
        .join('');
      if (filtersValue !== prevFilters.current) {
        prevFilters.current = filtersValue;
        (async () => fetch())();
      }
    } else {
      (async () => fetch())();
    }
  }, [filters]);
  return {
    requisites: requisites,
    requisite: requisites[0],
    loading: isLoading,
    refetch: fetch,
  };
};
