import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { TDeals, TDealsData } from '../model/types';
import { IMeta } from '../../../interfaces';

export const getDeals = async (
  filters?: [],
  page?: number
): Promise<TDealsData> => {
  try {
    const { data } = await axios.post<{
      data: TDeals[];
      meta: IMeta;
    }>(`${API_URL}/exchange-point-offer-requests/search`, {
      filters: filters,
      limit: 10,
      page: page ?? 1,
      includes: [
        { relation: 'offer' },
        { relation: 'offer.exchange_point.company' },
        { relation: 'requisite.currency' },
      ],
      sort: [{ field: 'created_at', direction: 'desc' }],
    });
    return { data: data?.data, meta: data.meta };
  } catch {
    return {
      data: [],
      meta: {} as IMeta,
    };
  }
};
