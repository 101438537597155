import { useCallback } from 'react';
import {
  BankOutlined,
  CheckCircleFilled,
  WalletOutlined,
} from '@ant-design/icons';

import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { axios } from 'shared/exios';
import { API_URL } from 'packages/keycloak-client/constants';
import {
  CurrencyIconStyled,
  ModalStyled,
  SectionInfo,
  SectionLabel,
  SectionStyled,
} from './ModalConfirmPaymentSent.styles';
import { TDeals } from 'pages/offers/model/types';

const requestUrl = (id: string | number) =>
  `${API_URL}/exchange-point-offer-requests/${id}/confirm-payment`;

export const ModalConfirmPaymentSent = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalConfirmPaymentSent)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmPaymentSent).options
  );

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios.put(requestUrl(modalOptions.id));

      modalOptions?.refetchData();
      notification.success({
        message: t('Отправка оплаты подтверждена'),
        icon: <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />,
        style: {
          borderLeft: '6px solid #13c2c2',
          width: '400px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
    } finally {
      closeModal(ModalKeys.ModalConfirmPaymentSent);
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(ModalKeys.ModalConfirmPaymentSent);
  }, [closeModal]);

  const deal = modalOptions.deal as TDeals;
  const requisite = deal?.requisite ?? {};
  const currency = requisite.currency ?? {};
  const data = {
    code: currency.code,
    fullName: deal.full_name ?? '',
    paymentName: currency.name,
    requisite: deal?.requisites,
    type: currency.type,
  };

  const isEmoney = data.type === 'emoney';

  const DefaultIcon = isEmoney ? WalletOutlined : BankOutlined;

  return (
    <ModalStyled
      open={isModalOpen}
      onOk={handleChangeOfferRequestStatus}
      onCancel={handleCloseModal}
      title={t('Подтверждение отправки оплаты')}
      footer={[
        <Button onClick={handleCloseModal}>{t('shared:кнопки.Отмена')}</Button>,
        <Button onClick={handleChangeOfferRequestStatus} type="primary">
          {t('shared:кнопки.Подтвердить')}
        </Button>,
      ]}
      width={636}
      closable={true}
      centered
    >
      <p>
        {t('Подтвердите что вы перевели средства', {
          currencyToBuy: deal?.offer?.crypto_payment_system_currency?.currency_key,
        })}
      </p>
      <SectionStyled>
        <div>
          <SectionLabel>{t('shared:тексты.Способ оплаты')}</SectionLabel>
          <SectionInfo>
            <CurrencyIconStyled
              code={data.code}
              inline
              fallbackIcon={<DefaultIcon />}
            />
            {data.paymentName}
          </SectionInfo>
        </div>
        <div>
          <SectionLabel>{t('Реквизиты')}</SectionLabel>
          <SectionInfo>{data.requisite}</SectionInfo>
        </div>
        {!isEmoney && (
          <div>
            <SectionLabel>{t('ФИО клиента')}</SectionLabel>
            <SectionInfo>{data.fullName}</SectionInfo>
          </div>
        )}
      </SectionStyled>
    </ModalStyled>
  );
};
