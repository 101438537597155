import { useEffect, useRef, useState } from 'react';
import { axios } from '../exios';
import { IResponseShell } from '../interfaces';
import { API_URL } from '../../packages/keycloak-client/constants';

type TPaymentCurrenciesData = {
  id: number;
  name: string;
  type: string;
  currency_key: string;
  code: string;
};
export const usePaymentCurrencies = ({ type }: { type: 'bank' | 'emoney' }) => {
  const [currencies, setCurrencies] = useState<TPaymentCurrenciesData[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.post<
          IResponseShell<TPaymentCurrenciesData[]>
        >(`${API_URL}/offer-payment-currencies/search`, {
          filters: [
            {
              field: 'type',
              operator: '=',
              value: type,
            },
          ],
          limit: 1000,
        });
        setCurrencies(data.data);
      } catch (e) {
        console.error(e);
        setCurrencies([]);
      }
    })();
  }, [type]);
  return {
    currencies: currencies,
  };
};
