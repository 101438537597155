export enum EOfferRequestStatusTagColors {
  'pending' = 'blue',
  'accepted' = 'blue',
  'cancelled' = 'red',
  'warranty_collateral' = 'orange',
  'ready' = 'geekblue',
  'expired' = 'red',
  'on_argument' = 'orange',
  'completed' = 'cyan',
  'waiting_for_fiat_payment' = 'blue',
}
