import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  CheckCircleTwoTone,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { Popover } from '@pankod/refine-antd';
import React from "react";
import {useTranslation} from "react-i18next";
import { GarantStatus, GoToExchangeStatus } from '../../../shared/components/icons';

type TColors = {
  red?: string
  blue?: string
  green?: string
}

interface DealStatusProps {
  status: string
  colors?: TColors
}

export const DealsStatus = (props: DealStatusProps) => {

  const {t} = useTranslation(['shared'])

  const {status, colors = {}} = props
  if (status === "pending") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.На рассмотрении')}
    >
      <ClockCircleFilled style={{color: '#85A5FF', fontSize: '16px'}}/>
    </Popover>
  }

  if (status === "accepted") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Принята')}
    >
      <CheckCircleFilled style={{color: '#4096FF', fontSize: '16px'}}/>
    </Popover>
  }

  if (status === "warranty_collateral") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Обеспечение гаранта')}
    >
      <span><GarantStatus /></span>
    </Popover>
  }

  if (status === "ready") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Готова к обмену')}
    >
      <span><GoToExchangeStatus /></span>
    </Popover>
  }

  if (status === "completed") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Выполнена')}
    >
      <CheckCircleFilled style={{color: '#36CFC9', fontSize: '16px'}}/>
    </Popover>
  }

  if (status === "expired") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Просрочена')}
    >
      <ClockCircleFilled style={{color: '#FF7875', fontSize: '16px'}}/>
    </Popover>
  }

  if (status === "on_argument") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Открыт спор')}
    >
      <ExclamationCircleFilled style={{color: '#FFC069', fontSize: '16px'}}/>
    </Popover>
  }

  if (status === "cancelled") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Отменена')}
    >
      <CloseCircleFilled style={{color: '#FF7875', fontSize: '16px'}}/>
    </Popover>
  }

  if (status === "waiting_for_fiat_payment") {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Ожидание оплаты фиатом')}
    >
      <ClockCircleFilled style={{color: '#69B1FF', fontSize: '16px'}}/>
    </Popover>
  }

  return (
    <ClockCircleFilled style={{color: '#a3a3a3', fontSize: '16px'}}/>
  )
}
