import { IInfoList } from '../../model/types';
import { Typography } from '@pankod/refine-antd';
import { Link } from '@pankod/refine-react-router-v6';
import styled, { css } from 'styled-components';

const ContainerStyled = styled.div`
  padding-left: 24px;
  display: grid;
  gap: 8px;
`;

const ItemsContainerStyled = styled.div`
  display: grid;
  grid-template-areas:
    'item1 item5'
    'item2 item6'
    'item3 item7'
    'item4 item8';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-content: space-between;
  gap: 8px;
`;

const ItemStyled = styled.div<{ area: string }>`
  ${({ area }) => css`
    grid-area: ${area};
  `}
`;

interface IProps extends IInfoList {
  header: string;
}

const RequestTabsData = ({ header, items }: IProps) => {
  return (
    <ContainerStyled>
      <Typography.Text strong>{header}</Typography.Text>
      <ItemsContainerStyled>
        {items.map(({ label, value, link, pos }) => (
          <ItemStyled area={'item' + (pos || 0)}>
            <Typography.Text type={'secondary'}>{label}</Typography.Text>{' '}
            {link ? (
              <Link to={link}>{value}</Link>
            ) : (
              <Typography.Text>{value}</Typography.Text>
            )}
          </ItemStyled>
        ))}
      </ItemsContainerStyled>
    </ContainerStyled>
  );
};

export default RequestTabsData;
