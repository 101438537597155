import { useMemo } from 'react';
import { IInfoList } from '../../model/types';
import { removeTrailingZeros } from '../../../../shared/helpers/removeTrailingZeros';
import { useTranslation } from 'react-i18next';
import { IRequest } from '../types';

type RequestStatus =
  | 'pending'
  | 'accepted'
  | 'cancelled'
  | 'warranty_collateral'
  | 'ready'
  | 'expired'
  | 'on_argument'
  | 'completed';

const needAddedInfo: RequestStatus[] = [
  'ready',
  'accepted',
  'warranty_collateral',
];

const useFormattedExchangeRequest = (request: IRequest): IInfoList => {
  const { t, i18n } = useTranslation('shared');

  return useMemo(() => {
    const initInfo = {
      items: [
        {
          label: t('shared:тексты.Пункт обмена двоеточие'),
          value: request.offer?.exchange_point?.name,
          pos: 1,
          link: `/points/${request.offer?.exchange_point_id}`,
        },
        {
          label: t('shared:тексты.Фикс точка стоимость сделки двоеточие'),
          pos: 5,
          value: `${request.crypto_commission_amount} ${request.offer?.cash_currency_code}`,
        },
        {
          label: t('shared:тексты.Актив двоеточие'),
          name: 'cash',
          value: `${removeTrailingZeros(
            request?.crypto_exchange_point_amount
          )} ${request.offer?.crypto_payment_system_currency?.currency_key}`,
        },
        {
          label: t('shared:тексты.Комиссия пункта обмена двоеточие'),
          pos: 6,
          value: `${request.commission_percent}%`,
        },
      ],
    };

    if (
      request.cash_account_owner_type === 'personal_account' &&
      request?.cash_user?.username
    ) {
      initInfo.items.push({
        label: request.offer?.is_buying_crypto
          ? t('shared:тексты.Откуда выдать фиат двоеточие')
          : t('shared:тексты.Как получить фиат двоеточие'),
        value: request?.cash_user?.username,
        link: `/staff/${request?.cash_user?.id}`,
        pos: 2,
      });
    }

    if (
      request.cash_account_owner_type === 'exchange_point' &&
      request?.cash_account?.name
    ) {
      initInfo.items.push({
        label: request.offer?.is_buying_crypto
          ? t('shared:тексты.Откуда выдать фиат двоеточие')
          : t('shared:тексты.Как получить фиат двоеточие'),
        value: request?.cash_account?.name,
        link: `/accounts/${request?.cash_account?.id}`,
        pos: 2,
      });
    }

    return initInfo;
  }, [request, t, i18n.language]);
};

export default useFormattedExchangeRequest;
