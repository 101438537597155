import { useTranslation } from 'react-i18next';
import { useRequestConfirmState } from '../../model/useRequestConfirmState';
import { FlexBlockStyled, StyledText } from '../OfferRequestConfirm.styles';

type PropsType = {
  isBuyingCrypto: boolean;
};

const FundsControlBlock = ({ isBuyingCrypto }: PropsType) => {
  const { t } = useTranslation(['shared']);

  const requestData = useRequestConfirmState((state) => state.requestData);

  return (
    <FlexBlockStyled vertical align="flex-start" justify="flex-start" gap={16}>
      <div>
        <StyledText>{t('shared:тексты.Реквизиты двоеточие')}</StyledText>
        <div>{requestData?.requisites}</div>
      </div>
      {requestData?.fullName && (
        <div>
          <StyledText>{t('shared:тексты.ФИО клиента двоеточие')}</StyledText>
          <div>{requestData?.fullName}</div>
        </div>
      )}
    </FlexBlockStyled>
  );
};

export default FundsControlBlock;
