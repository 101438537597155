import styled from 'styled-components';
import { Input, Radio } from '@pankod/refine-antd';

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ModalTitleStyled = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 32px;
`;
export const OptionFillerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const SpanStyled = styled.span`
  font-size: 12px;
  color: #888;
`;
export const TooltipTextStyled = styled.p`
  color: black;
`;
const { TextArea } = Input;
export const TextAreaStyled = styled(TextArea)`
  height: 70px;
  resize: none;
`;
export const RadioButtonStyled = styled(Radio.Button)`
  flex: 1;
  text-align: center;
`;
export const RadioGroupStyled = styled(Radio.Group)`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
`;
